import React, { useEffect, useState } from 'react'
import logo from '../../assets/imgs/logo.png'
import next from '../../assets/imgs/icosn/next.svg'
import { Link, useNavigate } from "react-router-dom";
import Loader from '../../admin/Loader';
import { ToastContainer, toast } from 'react-toastify'

const Register = () => {
  const [companyName, setcompanyName] = useState("");
  const [mobile, setmobile] = useState("");
  const [OTP, setOTP] = useState("");
  const [generatedOTP, setgeneratedOTP] = useState("");
  const [OTPdata, setOTPdata] = useState({});
  const [password, setPassword] = useState("");
  const [annual_turn_over, setannual_turn_over] = useState("");
  const [userType, setuserType] = useState(3);


  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState();
  let items = { companyName, mobile, annual_turn_over, password };
  const navigate = useNavigate();


  const SendOTP = (otp) => {
    console.log("send OTP ");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    var raw = JSON.stringify({
      "phone": mobile,
      "otp": otp,
      "userType": "3",
      "type":"register"
    });
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    fetch("https://bizfinn.co.in/sendOtp", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === true) {
          console.log(result);
          setOTPdata(result);
          // Display success toast
          toast.success(result.msg, {
            autoClose: 2000
          });
        } else {
          console.log(result);
          setOTPdata(result);
          // Display error toast
          toast.error(result.msg, {
            autoClose: 2000
          });
        }
      })
      .catch(error => {
        console.log('error', error);
        // Display error toast for network issues, if needed
        toast.error("Network error. Please try again later.", {
          autoClose: 2000
        });
      });
  };

  function generateOTP() {
    const otp = Math.floor(1000 + Math.random() * 9000);
    return otp.toString();
  }


  

  function sendOTPByEmail(otp) {
    // console.log(`Sending OTP ${otp} to`);
    // In a real application, you would send an email here using an email service.
  }

  const generateAndSendOTP = () => {
    const newOTP = generateOTP();
    // setOTP(newOTP);
    sendOTPByEmail(newOTP);
    SendOTP(newOTP)
    setOTP(newOTP)
  };

  // useEffect(()=>{
  //   SendOTP()
  // },[OTP])


  const BorrowerRegister = (e) => {
    e.preventDefault();
  
    // Check if the entered OTP matches the generated OTP
    if (otpSent === OTP) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
        "companyName": companyName,
        "mobile": mobile,
        "annual_turn_over": annual_turn_over,
      });
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
  
      fetch("https://bizfinn.co.in/registerBorrower", requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
  
          if (result?.success) {
            // Display a success toast message
            toast.success(result?.msg, {
              autoClose: 2000
            });
            localStorage.setItem("borrowertoken", result.data.fcmToken);
            localStorage.setItem("borroweruserid", result.data._id);
            localStorage.setItem("adminId" , result.data.admin)
            setTimeout(() => {
              navigate("/borrower-dashboard");
            }, 2000);
          } else {
            // Display an error toast message
            toast.error(result?.msg, {
              autoClose: 2000
            });
  
            if (result?.errors) {
              console.log('Error msg', result?.msg);
              setErrorMessage(result?.msg);
            } else {
              setErrorMessage(result?.msg);
            }
          }
        })
        .catch(error => {
          console.log('error', error);
          // Display an error toast message for network issues, if needed
          toast.error("Network error. Please try again later.", {
            autoClose: 2000
          });
        });
    } else {
      // Display an error message if the OTPs don't match
      toast.error("Entered OTP does not match. Please try again.", {
        autoClose: 2000
      });
    }
  }
  
  


 

  return (
    <>
      <ToastContainer />

      <div className='auth_register_page_main'>
        <section className='auth_main_section full_container auth_banner_img'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-6 col-md-12 col-sm-12'>
                <div className='auth_content_col'>
                  <img src={logo} alt='logo' className='img-fluid logo' />
                  <div className='auth_content_div'>
                    <h1>
                      Get the <br />
                      <span>
                        Funding <br /> You Need
                      </span>
                    </h1>
                    <p>
                      We provide working capital solutions to help businesses like
                      yours grow and succeed. And there is no collateral required.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-12 col-sm-12 '>
                <div className='auth_second_col_main_outer'>
                  <div className='auth_second_col_main'>
                    {/* <div className="form_round_box"></div> */}
                    <div className='content borrower_content'>
                      <form onSubmit={BorrowerRegister}>
                        <h2>Apply for Business Loan</h2>
                        <input
                          type='text'
                          className='form_input_box'
                          placeholder='Enter Company Name'
                          autoCorrect='off'
                          autoComplete='off'
                          value={companyName}
                          onChange={(e) => setcompanyName(e.target.value)}
                          required
                        />

                        <div className='register_phone_field'>
                          <input
                            type='number'
                            className='form_input_box'
                            placeholder='Enter Phone Number'
                            autoCorrect='off'
                            autoComplete='off'
                            value={mobile}
                            onChange={(e) => setmobile(e.target.value)}
                            required

                          />
                          <button className='phone_num_next_btn'  onClick={generateAndSendOTP}>
                            {/* <img src={next} alt='next' className='img-fluid' /> */}
                            SEND OTP
                          </button>
                        </div>
                        <input
                          type='tel'
                          className='form_input_box'
                          placeholder='Enter OTP Received'
                          autoCorrect='off'
                          autoComplete='off'
                          value={otpSent}
                          onChange={(e) => setOtpSent(e.target.value)}
                          required
                        />
                        <div className=''>
                          <select name='annual-turn' className='form_input_box' value={annual_turn_over}

                            onChange={(e) => setannual_turn_over(e.target.value)} required>
                            <option value=''>Select your annual turn over (in INR)</option>
                            <option value="upto 1cr">upto 1 CR</option>
                            <option value="1 - 10 CR">1 - 10 CR</option>
                            <option value="10 - 50 CR">10 - 50 CR</option>
                            <option value="50cr+">50+ CR</option>
                          </select>
                        </div>
                        <div className='register_checkbox'>
                          <input
                            type='checkbox'
                            id='registerTerms'
                            name='registerTerms'
                            value='yes'
                            required
                          />
                          <label htmlFor='registerTerms'>
                            {' '}
                            I hereby confirm that I have read, understood, and agree
                            to the <span style={{ whiteSpace: "nowrap" }}> "T & C"</span>, "Privacy Policy"
                          </label>
                        </div>

                        <div className='login_register_btn_div'>

                          <button
                            type="submit"
                            className="blue_btn login_register_btn"
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "Register"}{" "}
                          </button>
                          <Link
                            to='/borrower-login'
                            className='login_register_btn register_btn'
                          >
                            Sign In
                          </Link>
                        </div>
                        {/* =============== Error msg=============== */}
                        {/* <div className="error_msg">
                      <p>Wrong Credentials!</p>
                    </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Register

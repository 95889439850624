// import React from 'react'
// import Filter from '../admin/Filter'
// import back_btn from '../assets/imgs/icosn/back-btn.svg'
// import { Link } from 'react-router-dom'
// import LenderDashboardMain from './LenderDashboardMain'

// const LenderDashboard = () => {
//   return (
//     <LenderDashboardMain>
//       <section className='side_content_main_box'>
//         <div className='page_heading_div'>
//           <div className='back_btn_filter_main'>
//             <h2> Dashboard</h2>
//             <Filter />
//           </div>
//         </div>
//         <section className='dashboard_boxes_main_section'>
//           <div className='row'>
//             <div className=' col-lg-3 col-md-4 col-sm-12'>
//               <div className='dashboard_boxes_single'>
//                 <h2>3</h2>
//                 <h4>Pending Cases</h4>
//               </div>
//             </div>
//             <div className=' col-lg-3 col-md-4 col-sm-12'>
//               <div className='dashboard_boxes_single'>
//                 <h2>3</h2>
//                 <h4>Pending Cases</h4>
//               </div>
//             </div>
//             <div className=' col-lg-3 col-md-4 col-sm-12'>
//               <div className='dashboard_boxes_single'>
//                 <h2>3</h2>
//                 <h4>Pending Cases</h4>
//               </div>
//             </div>
//             <div className=' col-lg-3 col-md-4 col-sm-12'>
//               <div className='dashboard_boxes_single'>
//                 <h2>3</h2>
//                 <h4>Pending Cases</h4>
//               </div>
//             </div>
//           </div>
//         </section>
//       </section>
//     </LenderDashboardMain>
//   )
// }

// export default LenderDashboard



import React, { useEffect, useState } from 'react'
import LenderDashboardMain from './LenderDashboardMain'
// import Filter from './Filter'
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import graph_top from '../assets/imgs/icosn/graph-top.svg'
import graph_down from '../assets/imgs/icosn/graph-down.svg'
import cal_icon from '../assets/imgs/icosn/calender.svg'
import Filter from '../admin/Filter'
import { formatNumberToCr } from '../utils/ConvertNumbers';



const LenderDashboard = () => {


  const [TOVNumber, setTOVNumber] = useState('')
  const [GTVNumber, setGTVNumber] = useState('')
  const [activeDealsNumber, setactiveDealsNumber] = useState('')
  const [grossRevenueNumber, setgrossRevenueNumber] = useState('')
  const [NewsBulletinCategory, setNewsBulletinCategory] = useState('6M')
  const [NewsBulletinData, setNewsBulletinData] = useState(null)
  const [lenderId, setlenderId] = useState(localStorage.getItem('lenderuserid'))


  const [GrossTransactionValue, setGrossTransactionValue] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Gross Revenue",
        data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#FFB200",
        borderWidth: 1,
        pointStyle: 'none',
        pointRadius: 0,
      }
    ]

  })

  //graph color
  const [donutColors, setdonutColors] = useState([
    '#BC97CD',
    '#57B591',
    '#3B66C2',
    '#B1E3FF',
    '#ECB02B',
    '#FFACAC',
    '#A0E7E5'
  ],)

  //Graph labels
  const [typeOfLoanLabel, settypeOfLoanLabel] = useState([
    'U.S.T',
    'Vendor Financing',
    'S.B.D',
    'EXIM Financing',
    'S.B.L',
    'CL Or DL',
    'Other'
  ])



  const [newsBulletinGraphData, setnewsBulletinGraphData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Gross Transaction",
        data: [33, 25, 35, 51, 54, 76, 85, 41, 44, 65, 33, 53],
        fill: false,
        borderColor: "#4339F2",
        borderWidth: 1,
        pointStyle: 'none',
        pointRadius: 0,
      },
    ]
  })

  const [approvedChartLabel, setapprovedChartLabel] = useState([
    'U.S.T',
    'Vendor Financing',
    'S.B.D',
    'EXIM Financing',
    'S.B.L',
    'CL Or DL',
    'Other'
  ])

  const [rejectedLoanChartLabel, setrejectedLoanChartLabel] = useState([
    'Incomplete data',
    'Low CIBIL',
    'High Leverage',
    'Low Runway',
  ])


  //Graph data
  const [growthStatsData, setgrowthStatsData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Gross Revenue",
        data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#FFB200",
        borderWidth: 1,
        pointStyle: 'none',
        pointRadius: 0,
      },
      {
        label: "Gross Transaction",
        data: [33, 25, 35, 51, 54, 76, 85, 41, 44, 65, 33, 53],
        fill: false,
        borderColor: "#4339F2",
        borderWidth: 1,
        pointStyle: 'none',
        pointRadius: 0,
      },
      {
        label: "Disbursement",
        data: [33, 41, 44, 65, 33, 53, 33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#34B53A",
        borderWidth: 1,
        pointStyle: 'none',
        pointRadius: 0,

      },
    ]

  })

  const [loanStatusData, setloanStatusData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Approved",
        data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#34B53A",
        backgroundColor: "#34B53A",
        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,

      },
      {
        label: "Under Process",
        data: [33, 25, 35, 51, 54, 76, 85, 41, 44, 65, 33, 53],
        fill: true,
        borderColor: "#B1E3FF",
        backgroundColor: "#B1E3FF",

        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,

      },
      {
        label: "Rejected",
        data: [33, 41, 44, 65, 33, 53, 33, 25, 35, 51, 54, 76],
        fill: true,
        borderColor: "#3B66C2",
        backgroundColor: "#3B66C2",
        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,


      },
    ]

  })

  // DonutData 
  const [GraphTypeOfLoan, setGraphTypeOfLoan] = useState({
    labels: [
      'U.S.T',
      'Vendor Financing',
      'S.B.D',
      'EXIM Financing',
      'S.B.L',
      'CL Or DL',
      'Other'
    ],
    datasets: [
      {
        data: [12, 19, 5, 3, 4, 5, 3],
        backgroundColor: [
          '#BC97CD',
          '#57B591',
          '#3B66C2',
          '#B1E3FF',
          '#ECB02B',
          '#FFACAC',
          '#A0E7E5'
        ],

        borderWidth: 0,
      },
    ],

  })

  const [GraphApprovedCases, setGraphApprovedCases] = useState({
    labels: [
      'U.S.T',
      'Vendor Financing',
      'S.B.D',
      'EXIM Financing',
      'S.B.L',
      'CL Or DL',
      'Other'
    ],
    datasets: [
      {
        data: [12, 19, 5, 3, 4, 5, 3],
        backgroundColor: [
          '#BC97CD',
          '#57B591',
          '#3B66C2',
          '#B1E3FF',
          '#ECB02B',
          '#FFACAC',
          '#A0E7E5'
        ],

        borderWidth: 0,
      },
    ],

  })

  const [GraphRejectedLoanCases, setGraphRejectedLoanCases] = useState(
    {
      labels: ['Incomplete data', 'Low CIBIL', 'High Leverage', 'Low Runway'],
      datasets: [
        {
          data: [12, 19, 5, 3],
          backgroundColor: [
            '#BC97CD',
            '#57B591',
            '#3B66C2',
            '#B1E3FF',
          ],

          borderWidth: 0,
        },
      ],

    }
  )

  //percentage array
  const [graphLoanPercentage, setgraphLoanPercentage] = useState([])
  const [approvedLoanPercentage, setapprovedLoanPercentage] = useState([])
  const [rejectedLoanPercentage, setrejectedLoanPercentage] = useState([])


  const Growth_Statistics_options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            fontColor: '#1C1C1C', // Set the color of the y-axis tick labels
            fontFamily: "'Roboto', sans-serif"

          },
          grid: {
            display: false, // Set to false to hide horizontal grid lines
          },
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#1C1C1C',// Set the color of the y-axis tick labels
            fontFamily: "'Roboto', sans-serif"
          },
          grid: {
            display: false, // Set to false to hide horizontal grid lines
          },
        }
      ]
    },
    legend: {
      display: false,
      position: 'top',
      labels: {
        fontColor: '#9FA0A2',
        fontFamily: "'Roboto', sans-serif"
      }
    },

  }


  function shortenCurrencyNumber(number) {
    if (number >= 100000) {
      return (number / 100000).toFixed(1) + 'Lakh';
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k';
    } else {
      return number.toString();
    }
  }


  const handleTypeOfLoanDonutGraph = (result) => {
    const TypeOfnumberArray = [];
    for (const key in result.allTypeOfLoan) {
      TypeOfnumberArray.push(result.allTypeOfLoan[key]);
    }
    console.log(TypeOfnumberArray);

    const total = TypeOfnumberArray.reduce((acc, number) => acc + number, 0);
    const TOLpercentageArray = TypeOfnumberArray.map(number => (number / total * 100).toFixed(2) + '%');
    console.log(TOLpercentageArray);
    setgraphLoanPercentage(TOLpercentageArray)

    const updatedLoanGraphdata = {
      labels: [
        'U.S.T',
        'Vendor Financing',
        'S.B.D',
        'EXIM Financing',
        'S.B.L',
        'CL Or DL',
        'Other'
      ],
      datasets: [
        {
          data: TypeOfnumberArray,
          backgroundColor: [
            '#BC97CD',
            '#57B591',
            '#3B66C2',
            '#B1E3FF',
            '#ECB02B',
            '#FFACAC',
            '#A0E7E5'
          ],

          borderWidth: 0,
        },
      ],

    }
    setGraphTypeOfLoan(updatedLoanGraphdata)
  }

  const [noDataGraphApprovedCases, setnoDataGraphApprovedCases] = useState({
    labels: [
      'No Data'
    ],
    datasets: [
      {
        data: [1],
        backgroundColor: [
          'grey'
        ],

        borderWidth: 0,
      },
    ],

  })

  const [isRejectedCasesDataZero, setisRejectedCasesDataZero] = useState(false)
  const [isApprovedCasesDataZero, setisApprovedCasesDataZero] = useState(false)



  const handleApprovedLoanDonutGraph = (result) => {

    const allValuesAreZero = Object.values(result.approved_chart).every(value => value === 0)
    if (allValuesAreZero) {
      setisApprovedCasesDataZero(true)
      return;
    }
    const ApprovedLoanNumberArray = [];
    for (const key in result.approved_chart) {
      ApprovedLoanNumberArray.push(result.approved_chart[key]);
    }
    console.log(ApprovedLoanNumberArray);

    const Apptrovedotal = ApprovedLoanNumberArray.reduce((acc, number) => acc + number, 0);
    const ApppercentageArray = ApprovedLoanNumberArray.map(number => (number / Apptrovedotal * 100).toFixed(2) + '%');
    console.log(ApppercentageArray);
    setapprovedLoanPercentage(ApppercentageArray)

    const approvedLoanGraphData = {
      labels: [
        'U.S.T',
        'Vendor Financing',
        'S.B.D',
        'EXIM Financing',
        'S.B.L',
        'CL Or DL',
        'Other'
      ],
      datasets: [
        {
          data: ApprovedLoanNumberArray,
          backgroundColor: [
            '#BC97CD',
            '#57B591',
            '#3B66C2',
            '#B1E3FF',
            '#ECB02B',
            '#FFACAC',
            '#A0E7E5'
          ],

          borderWidth: 0,
        },
      ],

    }
    setGraphApprovedCases(approvedLoanGraphData)
  }

  const handleRejectedLoanDonutGraph = (result) => {

    const allValuesAreZero = Object.values(result.rejection_chart).every(value => value === 0)
    if (allValuesAreZero) {
      setisRejectedCasesDataZero(true)
      return;
    }
    {
      const RejectionLoanNumberArray = [];
      for (const key in result.rejection_chart) {
        RejectionLoanNumberArray.push(result.rejection_chart[key]);
      }
      console.log(RejectionLoanNumberArray);

      const Apptrovedotal = RejectionLoanNumberArray.reduce((acc, number) => acc + number, 0);
      const ApppercentageArray = RejectionLoanNumberArray.map(number => (number / Apptrovedotal * 100).toFixed(2) + '%');
      console.log(ApppercentageArray);
      setrejectedLoanPercentage(ApppercentageArray)

      const approvedLoanGraphData = {
        labels: [
          'U.S.T',
          'Vendor Financing',
          'S.B.D',
          'EXIM Financing',
          'S.B.L',
          'CL Or DL',
          'Other'
        ],
        datasets: [
          {
            data: RejectionLoanNumberArray,
            backgroundColor: [
              '#BC97CD',
              '#57B591',
              '#3B66C2',
              '#B1E3FF',
              '#ECB02B',
              '#FFACAC',
              '#A0E7E5'
            ],

            borderWidth: 0,
          },
        ],

      }
      setGraphRejectedLoanCases(approvedLoanGraphData)
    }
  }

  const handleGrowthStatsGraph = (result) => {
    const gross_revenue = [];
    const gross_transaction = [];
    const disbursement = [];

    const data = result.growth_statistics_year;

    // Iterate over the data object and extract the values for each month
    for (const month in data) {
      gross_revenue.push(data[month].gross_revenue.toString());
      gross_transaction.push(data[month].gross_transaction.toString());
      disbursement.push(data[month].disbursement.toString());
    }

    const growthStatsData = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Gross Revenue",
          data: gross_revenue,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "#FFB200",
          borderWidth: 1,
          pointStyle: 'none',
          pointRadius: 0,
        },
        {
          label: "Gross Transaction",
          data: gross_transaction,
          fill: false,
          borderColor: "#4339F2",
          borderWidth: 1,
          pointStyle: 'none',
          pointRadius: 0,
        },
        {
          label: "Disbursement",
          data: disbursement,
          fill: false,
          borderColor: "#34B53A",
          borderWidth: 1,
          pointStyle: 'none',
          pointRadius: 0,

        },
      ]

    }
    setgrowthStatsData(growthStatsData)
  }

  const handleLoanStatusGraph = (result) => {

    const approvedArray = [];
    const rejectedArray = [];
    const progressArray = [];

    const data = result.loan_status_year;

    // Iterate over the data object and extract the values for each month
    for (const month in data) {
      approvedArray.push(data[month].approved.toString());
      rejectedArray.push(data[month].rejected.toString());
      progressArray.push(data[month].progress.toString());
    }


    const Loan_Status_data = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Approved",
          data: approvedArray,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "#34B53A",
          backgroundColor: "#34B53A",
          maxBarThickness: 5,
          minBarLength: 2,
          categorySpacing: 20,

        },
        {
          label: "Under Process",
          data: progressArray,
          fill: true,
          borderColor: "#FFB200",
          backgroundColor: "#FFB200",

          maxBarThickness: 5,
          minBarLength: 2,
          categorySpacing: 20,

        },
        {
          label: "Rejected",
          data: rejectedArray,
          fill: true,
          borderColor: "#3B66C2",
          backgroundColor: "#3B66C2",
          maxBarThickness: 5,
          minBarLength: 2,
          categorySpacing: 20,


        },
      ]

    }


    setloanStatusData(Loan_Status_data)

  }

  const handleGrossTransactionValue = (result) => {
    const gross_transaction = [];

    const data = result.growth_statistics_year;

    // Iterate over the data object and extract the values for each month
    for (const month in data) {
      gross_transaction.push(data[month].gross_transaction.toString());
    }

    const grossTransctionValueData = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      datasets: [
        {
          label: "Gross Revenue",
          data: gross_transaction,
          fill: false,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "#FFB200",
          borderWidth: 1,
          pointStyle: 'none',
          pointRadius: 0,
        },
      ]

    }
    setGrossTransactionValue(grossTransctionValueData)
  }


  const handleNewBulletinChange = (NewsBulletinCategory) => {
    setNewsBulletinCategory(NewsBulletinCategory);
    let NewsBullitinFuncData = NewsBulletinData;
    let labels = []
    let gross_transaction = []
    let valuedata;
    console.log(NewsBulletinCategory);
    if (NewsBulletinCategory == '6M') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.sixMonthData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.sixMonthData;
    }
    else if (NewsBulletinCategory == 'YTD') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.year_to_todayData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.year_to_todayData;
    }
    else if (NewsBulletinCategory == 'Y-Y') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.year_to_yearData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.year_to_yearData;
    }
    else if (NewsBulletinCategory == 'Q-Q') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.quarterData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.quarterData;
    }
    else if (NewsBulletinCategory == 'M-M') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.month_monthData).map(month => month.charAt(0).toUpperCase() + month.slice(1)).slice().reverse();
      valuedata = NewsBullitinFuncData.month_monthData;
    }

    for (const month in valuedata) {
      gross_transaction.push(valuedata[month].gross_transaction.toString());
    }
    console.log(labels, gross_transaction);

    if (NewsBulletinCategory == 'M-M') {
      gross_transaction = gross_transaction.slice().reverse()
    }

    let newsBulletinGraphProjectedData = {
      labels: labels,
      datasets: [
        {
          label: "Gross Transaction",
          data: gross_transaction,
          fill: false,
          borderColor: "#4339F2",
          borderWidth: 1,
          pointStyle: 'none',
          pointRadius: 0,
        },
      ]

    }
    setnewsBulletinGraphData(newsBulletinGraphProjectedData)
  }

  const [newBulletinAmount, setnewBulletinAmount] = useState(0)



  const handleNewBulletin = async (NewsBullitin) => {
    setNewsBulletinData(NewsBullitin)
    let NewsBullitinFuncData = NewsBulletinData ? NewsBulletinData : NewsBullitin
    let labels = []
    let gross_transaction = []
    let valuedata;
    console.log(NewsBulletinCategory);
    if (NewsBulletinCategory == '6M') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.sixMonthData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.sixMonthData;
    }
    else if (NewsBulletinCategory == 'YTD') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.year_to_todayData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.year_to_todayData;
    }
    else if (NewsBulletinCategory == 'Y-Y') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.year_to_yearData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.year_to_yearData;
    }
    else if (NewsBulletinCategory == 'Q-Q') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.quarterData).map(month => month.charAt(0).toUpperCase() + month.slice(1));
      valuedata = NewsBullitinFuncData.quarterData;
    }
    else if (NewsBulletinCategory == 'M-M') {
      console.log(NewsBullitinFuncData);
      labels = Object.keys(NewsBullitinFuncData.month_monthData).map(month => month.charAt(0).toUpperCase() + month.slice(1)).slice().reverse();
      valuedata = NewsBullitinFuncData.month_monthData;
    }

    for (const month in valuedata) {
      gross_transaction.push(valuedata[month].gross_transaction.toString());
    }
    console.log(labels, gross_transaction);

    if (NewsBulletinCategory == 'M-M') {
      gross_transaction = gross_transaction.slice().reverse()
    }

    let disBusAmount = 0
    gross_transaction.map(val => { disBusAmount = disBusAmount + parseFloat(val) })

    setnewBulletinAmount(formatNumberToCr(disBusAmount))

    let newsBulletinGraphProjectedData = {
      labels: labels,
      datasets: [
        {
          label: "Gross Transaction",
          data: gross_transaction,
          fill: false,
          borderColor: "#4339F2",
          borderWidth: 1,
          pointStyle: 'none',
          pointRadius: 0,
        },
      ]

    }
    setnewsBulletinGraphData(newsBulletinGraphProjectedData)
  }

  console.log(newBulletinAmount)



  const getDashboardData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    try {
      const response = await fetch(`https://bizfinn.co.in/lender/dashbord/${lenderId}`, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);

      //Set Box values
      setTOVNumber(formatNumberToCr(result.total_origination_value))
      setGTVNumber(formatNumberToCr(result.gross_transaction_value))
      setactiveDealsNumber(shortenCurrencyNumber(result.active_cases_count))
      setgrossRevenueNumber(formatNumberToCr(result.gross_revenue))
      handleNewBulletin(result.NewsBullitin)

      //Graphs
      // handleGrowthStatsGraph(result)
      handleGrossTransactionValue(result)
      handleLoanStatusGraph(result)

      //Type of Loan
      handleTypeOfLoanDonutGraph(result)

      //Approved Loan
      handleApprovedLoanDonutGraph(result)

      //Rejected Loan
      handleRejectedLoanDonutGraph(result)




    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {

    getDashboardData()

  }, [])



  // ============================
  // ====Loan Status=======
  // ============================

  const Loan_Status_data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Approved",
        data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#BC97CD",
        backgroundColor: "#BC97CD",
        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,

      },
      {
        label: "Under Process",
        data: [33, 25, 35, 51, 54, 76, 85, 41, 44, 65, 33, 53],
        fill: true,
        borderColor: "#B1E3FF",
        backgroundColor: "#B1E3FF",

        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,

      },
      {
        label: "Rejected",
        data: [33, 41, 44, 65, 33, 53, 33, 25, 35, 51, 54, 76],
        fill: true,
        borderColor: "#95A4FC",
        backgroundColor: "#95A4FC",
        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,


      },
    ]

  };

  const Loan_Status_options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            fontColor: '#1C1C1C', // Set the color of the y-axis tick labels
            fontFamily: "'Roboto', sans-serif"

          },
          grid: {
            display: false, // Set to false to hide horizontal grid lines
          },
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#1C1C1C',// Set the color of the y-axis tick labels
            fontFamily: "'Roboto', sans-serif"
          },
          grid: {
            display: false, // Set to false to hide horizontal grid lines
          },
        }
      ]
    },

    legend: {
      display: false,
      position: 'top',
      labels: {
        fontColor: '#9FA0A2',
        fontFamily: "'Roboto', sans-serif"
      }
    },

  }

  // ============================
  // ====Type of loan originated=======
  // ============================

  const Type_of_loan_data = {
    labels: ['Term', 'SCF', 'Export', 'WCDL'],
    datasets: [
      {
        data: [12, 19, 5, 3],
        backgroundColor: [
          '#BC97CD',
          '#57B591',
          '#3B66C2',
          '#B1E3FF',
        ],

        borderWidth: 0,
      },
    ],

  };
  const Type_of_loan_options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
  };



  // ============================
  // ====Approved Loan originated=======
  // ============================

  const Approved_Loan_data = {
    labels: ['Term', 'SCF', 'Export', 'WCDL'],
    datasets: [
      {
        data: [12, 19, 5, 3],
        backgroundColor: [
          '#BC97CD',
          '#57B591',
          '#3B66C2',
          '#B1E3FF',
        ],

        borderWidth: 0,
      },
    ],

  };
  const Approved_Loan_options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },

  };


  // ============================
  // ====New Bulletin=======
  // ============================

  const New_Bulletin_data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Approved",
        data: [33, 53, 85, 41, 44, 65, 33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#BC97CD",
        backgroundColor: "#BC97CD",
        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,

      },
      {
        label: "Under Process",
        data: [33, 25, 35, 51, 54, 76, 85, 41, 44, 65, 33, 53],
        fill: true,
        borderColor: "#B1E3FF",
        backgroundColor: "#B1E3FF",

        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,

      },
      {
        label: "Rejected",
        data: [33, 41, 44, 65, 33, 53, 33, 25, 35, 51, 54, 76],
        fill: true,
        borderColor: "#95A4FC",
        backgroundColor: "#95A4FC",
        maxBarThickness: 5,
        minBarLength: 2,
        categorySpacing: 20,


      },
    ]

  };
  const New_Bulletin_options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            fontColor: '#1C1C1C', // Set the color of the y-axis tick labels
            fontFamily: "'Roboto', sans-serif"

          },
          grid: {
            display: false, // Set to false to hide horizontal grid lines
          },
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: '#1C1C1C',// Set the color of the y-axis tick labels
            fontFamily: "'Roboto', sans-serif"
          },
          grid: {
            display: false, // Set to false to hide horizontal grid lines
          },
        }
      ]
    },
    legend: {
      display: false,
      position: 'top',
      labels: {
        fontColor: '#9FA0A2',
        fontFamily: "'Roboto', sans-serif"
      }
    },

  }


  // ============================
  // ====Rejection reason=======
  // ============================

  const Rejection_reason_data = {
    labels: ['Incomplete data', 'Low CIBIL', 'High Leverage', 'Low Runway'],
    datasets: [
      {
        data: [12, 19, 5, 3],
        backgroundColor: [
          '#BC97CD',
          '#57B591',
          '#3B66C2',
          '#B1E3FF',
        ],

        borderWidth: 0,
      },
    ],

  };
  const Rejection_reason_options = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      display: false,
    },
  };


  const onDateChange=(fromDate , toDate)=>{
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    fetch(`https://bizfinn.co.in/lender/dashbord/${lenderId}?fromDate=${fromDate}&toDate=${toDate}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setTOVNumber(shortenCurrencyNumber(result.total_origination_value))
        setGTVNumber(shortenCurrencyNumber(result.gross_transaction_value))
        setactiveDealsNumber(shortenCurrencyNumber(result.active_cases_count))
        setgrossRevenueNumber(shortenCurrencyNumber(result.gross_revenue))
      })
      .catch(error => {
        console.log('error', error)
      });
  }

  return (
    <LenderDashboardMain>
      <section className="side_content_main_box">
        <div className="page_heading_div">
          <div className="back_btn_filter_main">
            <h2>Dashboard</h2>
            <Filter onDateChange={onDateChange} />
          </div>
        </div>
        <section className="dashboard_boxes_main_section">
          <div className='row'>
            <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12'>
              {/* ========Top Row========= */}
              <div className="row">
                <div className=" col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="dashboard_boxes_single box_bg_1">
                    <h2>Total Origination value</h2>
                    <div className='flex'>
                      <h4>{TOVNumber}</h4>
                    </div>
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="dashboard_boxes_single box_bg_2">
                    <h2>Gross Transaction value</h2>
                    <div className='flex'>
                      <h4>{GTVNumber}</h4>
                    </div>
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="dashboard_boxes_single box_bg_1">
                    <h2>Active Deals</h2>
                    <div className='flex'>
                      <h4>{activeDealsNumber}</h4>
                    </div>
                    {/* <div className='flex boxes_bottom_details'>
                      <h4>Average Deal Size</h4>
                      <h4 className='green_status'>12CR</h4>
                    </div> */}
                  </div>
                </div>
                <div className=" col-xl-3 col-lg-6 col-md-6 col-sm-12">
                  <div className="dashboard_boxes_single box_bg_2">
                    <h2>Gross Revenue</h2>
                    <div className='flex'>
                      <h4>{grossRevenueNumber}</h4>
                    </div>
                    {/* <div className='flex boxes_bottom_details'>
                      <h4>Net Margin</h4>
                      <h4 className='green_status'>12CR</h4>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* =======Second Row========== */}
              <div className='row'>





                {/* Loan Status */}
                <div className='col-lg-12 col-md-12 col-sm-12' >
                  <div className='graph_bg_box'>
                    <div className=''>
                      <div className='row'>
                        <div className='col-5'>
                          <h3 className='graph_headeings'> Loan Status</h3>
                        </div>
                        <div className='col-7'>
                          <div className='row'>
                            <div className='col-lg-4 col-md-4 col-sm-4 col-xs-6 '>
                              <div className="legend-item">
                                <div className="legend-indicator" style={{ background: '#34B53A', borderRadius: '50%', minWidth: '6px', height: '6px' }}></div>
                                <span className='legend_text'> Approved</span>
                              </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-4 col-xs-6'>
                              <div className="legend-item">
                                <div className="legend-indicator" style={{ background: '#FFB200', borderRadius: '50%', minWidth: '6px', height: '6px' }}></div>
                                <span className='legend_text'> Under Process</span>
                              </div>
                            </div>
                            <div className='col-lg-4 col-md-4 col-sm-4 col-xs-6'>
                              <div className="legend-item">
                                <div className="legend-indicator" style={{ background: '#3B66C2', borderRadius: '50%', minWidth: '6px', height: '6px' }}></div>
                                <span className='legend_text'> Rejected</span>
                              </div>
                            </div>
                          </div>
                          <div className="legend">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div >
                      <Bar data={loanStatusData} options={Loan_Status_options} />
                    </div>

                  </div>
                </div>


                {/* Type of loan originated */}
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='graph_bg_box'>
                    <div className='graph_gap_headings'>
                      <h3 className='graph_headeings'> Type of loan originated</h3>
                    </div>
                    <div>
                      <div className='row'>
                        <div className='col-lg-6 col-md-12 col-sm-12 d_flex_b_center'>
                          <div>
                            <Doughnut data={GraphTypeOfLoan} options={Type_of_loan_options} />
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 side_legends'>
                          <div className='row'>

                            {typeOfLoanLabel.map((data, index) => (
                              <div className='col-lg-12 col-md-12 col-sm-12 '>
                                <div className="legend_item_main">
                                  <div className='legend-item'>
                                    <div className="legend-indicator" style={{ background: donutColors[index], borderRadius: '50%', minWidth: '6px', height: '6px' }}></div>
                                    <span className='legend_text'>{data}</span>
                                  </div>
                                  <h3 className='legend_text_value'>{graphLoanPercentage[index] == "NaN%" ? '0%' : graphLoanPercentage[index]}</h3>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="legend">
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                {/* Approved Loan */}
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='graph_bg_box'>
                    <div className='graph_gap_headings'>
                      <h3 className='graph_headeings'> Approved Loan</h3>
                    </div>
                    <div>

                      <div className='row' style={{ marginTop: "40px" }}>
                        <div className='col-lg-6 col-md-12 col-sm-12 d_flex_b_center'>
                          <div>
                            <Doughnut data={isApprovedCasesDataZero ? noDataGraphApprovedCases : GraphApprovedCases} options={Approved_Loan_options} />
                          </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 side_legends'>
                          <div className='row'>


                            {
                              <>
                                {typeOfLoanLabel.map((data, index) => (
                                  <div className='col-lg-12 col-md-12 col-sm-12 '>
                                    <div className="legend_item_main">
                                      <div className='legend-item'>
                                        <div className="legend-indicator" style={{ background: donutColors[index], borderRadius: '50%', minWidth: '6px', height: '6px' }}></div>
                                        <span className='legend_text'>{data}</span>
                                      </div>
                                      <h3 className='legend_text_value'>{approvedLoanPercentage[index] == "NaN%" ? '0%' : approvedLoanPercentage[index]}</h3>
                                    </div>
                                  </div>
                                ))}
                              </>
                            }



                          </div>
                          <div className="legend">
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>






              </div>
            </div>

            {/* Second COl */}
            <div className=' col-xl-4 col-lg-4 col-md-4 col-sm-12'>
              {/* New Bulletin */}
              <div className='graph_bg_boxLender'>
                <h3 className='graph_headeings'> News Bulletin</h3>


                <div className='row '>
                  <div className='col-lg-4 col-md-4 col-sm-12'>
                    <div className='New_Bulletin_box_big_font'>
                      <h1>{newBulletinAmount}</h1>
                      <h2>Disbursement Amount</h2>
                    </div>
                  </div>
                  <div className='col-lg-8 col-md-8 col-sm-12'>
                    <div className='New_Bulletin_white_box_main'>
                      <div className={`New_Bulletin_white_box ${NewsBulletinCategory == '6M' ? 'selected-date-time-element' : ''}`} onClick={() => handleNewBulletinChange('6M')}>
                        <h3>6M</h3>
                      </div>
                      <div className='left-border-line'>
                        <h3></h3>
                      </div>
                      <div className={`New_Bulletin_white_box ${NewsBulletinCategory == 'YTD' ? 'selected-date-time-element' : ''}`} onClick={() => handleNewBulletinChange('YTD')}>
                        <h3>YTD</h3>
                      </div>
                      <div className='left-border-line'>
                        <h3></h3>
                      </div>
                      <div className={`New_Bulletin_white_box ${NewsBulletinCategory == 'Y-Y' ? 'selected-date-time-element' : ''}`} onClick={() => handleNewBulletinChange('Y-Y')}>
                        <h3>Y-Y</h3>
                      </div>
                      <div className='left-border-line'>
                        <h3></h3>
                      </div>
                      <div className={`New_Bulletin_white_box ${NewsBulletinCategory == 'Q-Q' ? 'selected-date-time-element' : ''}`} onClick={() => handleNewBulletinChange('Q-Q')}>
                        <h3>Q-Q</h3>
                      </div>
                      <div className='left-border-line'>
                        <h3></h3>
                      </div>
                      <div className={`New_Bulletin_white_box ${NewsBulletinCategory == 'M-M' ? 'selected-date-time-element' : ''}`} onClick={() => handleNewBulletinChange('M-M')}>
                        <h3>M-M</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <Line data={newsBulletinGraphData} options={Growth_Statistics_options} />
                </div>

                {/* <div className='row New_Bulletin_box_groth_col my-4'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='groth_col'>
                        <h3>Growth (MoM)</h3>
                        <div className='bg_round_box'>
                          <h4>GTV</h4> <span className='green_status'>+15.03% <img src={graph_top} alt='img ' /> </span>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                      <div className='groth_col'>
                        <h3>Growth (YoY)</h3>
                        <div className='bg_round_box'>
                          <h4>GTV</h4> <span className='green_status'>+15.03% <img src={graph_top} alt='img ' /> </span>
                        </div>
                      </div>
                    </div>
                  </div> */}

              </div>

              {/* Rejection reason */}
              <div className='' style={{ height: '380px' }}>
                <div className='graph_bg_box' >
                  <div className='graph_gap_headings'>
                    <h3 className='graph_headeings'> Rejection reason</h3>
                  </div>
                  <div>
                    <div className='row' style={{ marginTop: "90px" }}>
                      <div className='col-lg-6 col-md-12 col-sm-12 d_flex_b_center'>
                        <div>
                          <Doughnut data={isRejectedCasesDataZero ? noDataGraphApprovedCases : GraphRejectedLoanCases} options={Rejection_reason_options} />
                        </div>
                      </div>
                      <div className='col-lg-6 col-md-12 col-sm-12 side_legends'>
                        <div className='row'>

                          <>
                            {rejectedLoanChartLabel.map((data, index) => (
                              <div className='col-lg-12 col-md-12 col-sm-12 '>
                                <div className="legend_item_main">
                                  <div className='legend-item'>
                                    <div className="legend-indicator" style={{ background: donutColors[index], borderRadius: '50%', minWidth: '6px', height: '6px' }}></div>
                                    <span className='legend_text'>{data}</span>
                                  </div>
                                  <h3 className='legend_text_value'>{rejectedLoanPercentage[index] == "NaN%" ? '0%' : rejectedLoanPercentage[index]}</h3>
                                </div>
                              </div>
                            ))}</>



                        </div>
                        <div className="legend">
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>




            </div>
          </div>

        </section>
      </section>
    </LenderDashboardMain>
  )
}

export default LenderDashboard
